import React from "react";
import { useContext } from "react";
import { FaMap, FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { GlobalDataContext } from "../../context/context";
import PrimaryButton from "./boton/PrimaryButton";

const TransparentHeader = ({ headertitle, bgimg }) => {
    const { rpdata } = useContext(GlobalDataContext)
    return (
        <section className="w-full lg:h-[90vh] h-[50vh]  flex lg:flex-row flex-col pt-5">
            <div className="w-[8%] h-full -mx-5 lg:block hidden ">
                <div className="flex flex-col w-full h-full py-10">
                    <div className="w-full h-1/3 flex items-center">
                        <div className="-rotate-90 w-full h-1/2 flex flex-col justify-center items-center space-x-2">
                            <h1 className="text-white font-semibold text-base">Works Days</h1>
                            <div className="flex w-[150px]">
                                <FaRegCalendarAlt className='text-[18px] text-primary' />
                                <h1 className="text-white font-normal text-xs ml-2">{rpdata?.dbPrincipal?.workdays?.[0].day}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-1/3 flex items-center">
                        <div className="-rotate-90 w-full h-1/2 flex flex-col justify-center items-center space-x-2">
                            <h1 className="text-white font-semibold text-base">Work Hours</h1>
                            <div className="flex w-[150px]">
                                <FaRegClock className='text-[18px] text-primary' />
                                <h1 className="text-white font-normal text-xs ml-2">{rpdata?.dbPrincipal?.workHours?.[0].hour}</h1>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-1/3 flex items-center">
                        <div className="-rotate-90 w-full h-1/2 flex flex-col justify-center items-center space-x-2">
                            <h1 className="text-white font-semibold text-base">Location</h1>
                            <div className="flex w-[120px]">
                                <FaMap className='text-[18px] text-primary' />
                                <h1 className="text-white font-normal text-xs ml-2">{rpdata?.dbPrincipal?.location?.[0]?.address}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-[100%] lg:w-[95%] md:h-[90vh] h-auto flex flex-col justify-center items-center relative lg:mr-5 mr-0'>
                {/* image */}
                <div
                    className='md:w-[100%] w-full md:h-full h-[50vh] overflow-y-hidden relative before:z-10 before:absolute before:w-full before:h-full lg:rounded-tr-[40px] rounded-tr-[0px] rounded-bl-[40px] before:bg-[#11111167]'
                    style={{ backgroundImage: `url(${bgimg})`, backgroundSize: "cover", backgroundPosition: "center" }}
                >
                </div>

                {/* ------------- */}
                <div className='h-auto lg:w-[50%] w-[90%] flex flex-col gap-4 lg:z-50 z-10 text-white absolute top-1/2 -translate-y-1/2 left-[48%] lg:right-[10%] -translate-x-1/2 lg:-translate-x-0 '>
                    <h1 className='lg:text-[58px] md:text-[30px] text-[38px] font-extrabold text-right text-white'>
                        {headertitle}
                    </h1>
                    {/* <span className='lg:text-[18px] md:text-[18px] text-[12px] font-extrabold text-right text-white'>{text}</span> */}
                    <div className='w-full h-auto flex justify-end'>
                        <PrimaryButton direction text={"Call Us Now"} urlPhone btnphone={rpdata?.dbPrincipal?.phones?.[0]?.phone} />
                    </div>
                </div>
            </div>
            <div className='lg:hidden flex'>
                <div className="w-full flex items-center justify-center space-x-10">
                    <div className="w-auto flex justify-center items-center space-x-2">
                        <FaRegCalendarAlt className='text-[18px] text-primary' />
                        <div className="flex flex-col">
                            <span className="text-white uppercase font-semibold text-base">{rpdata?.dbPrincipal?.workdays?.[0].day}</span>
                            <span className="text-white uppercase font-normal text-xs">{rpdata?.dbPrincipal?.workHours?.[0].hour}</span>
                        </div>
                    </div>

                    <div className="w-auto flex justify-center items-center space-x-2">
                        <FaMap className='text-[18px] text-primary' />
                        <div className="flex flex-col">
                            <span className="text-white uppercase font-semibold text-base">Location</span>
                            <h1 className="text-white font-normal text-xs ml-2">{rpdata?.dbPrincipal?.location?.[0]?.address}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
};

export default TransparentHeader;